@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-track {
  background-color: rgb(217 217 217/ 30%);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(217 217 217/ 80%);
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 3px;
}
